import React from "react"
import { Link } from "gatsby"

export default function Investors() {
  return (
    <section id="about">
      <div id="investors_banner">
        <div className="container">
          <div
            className="investors_banner-info"
            data-aos="zoom-in"
            data-aos-delay="100"
          >
           <div className="banner-text">
              <h2 style={{textAlign: "center",}}>We Invest in your Success</h2>
            </div>
            {/* <hr></hr> */}
            {/* <p className="banner-text">
            <br></br>
                Xccelerata unites bold investors with the most promising start-ups to make a genuine global impact. 
               <br></br>
                Our strategy is driven by a global network of renowned industry experts and sponsors. These ardent professionals interact daily with start-up founders to lead, mentor, and guide them along their path. Because of our global reach, we have developed a diversified strategy and partner ecosystem that provides investors with a highly qualified deal flow.
                <br></br>
                Over 25 years of industry experience, we have dedicated our time and resources and connected several start-ups with corporate partners in industry verticals driving innovation. 
                
            </p>
            <br/> */}

          </div>
        </div>
      </div>
      {/* <div className="container" data-aos="fade-up">
        <div className="row row-eq-height justify-content-center">
          <div className="col-lg-12 mb-12 center-div"></div>
          <div className="container" data-aos="fade-up">
            <div className="row about-extra"> */}
              <div
                className="col-lg-12 pt-4 pt-lg-0 order-2 order-lg-1"
                data-aos="fade-right"
              >
                <p style={{textAlign: "left", marginLeft:"30px", marginRight: "30px",}}>
                  Xccelerata unites bold investors with the most promising start-ups to make a genuine global impact.
                </p>
                <p style={{ textAlign: "left", marginLeft:"30px", marginRight: "30px",}}>
                  Our strategy is driven by a global network of renowned industry experts and sponsors. These ardent professionals interact daily with start-up founders to lead, mentor, and guide them along their path. Because of our global reach, we have developed a diversified strategy and partner ecosystem that provides investors with a highly qualified deal flow.
                </p>
                <p style={{ textAlign: "left", marginLeft:"30px", marginRight: "30px",}}>
                  Over 25 years of industry experience, we have dedicated our time and resources and connected several start-ups with corporate partners in industry verticals driving innovation.

                </p>
                <div class="section-header" style={{ textAlign: "center", }}>
                  <h5 class="section-title"><b style={{ textAlign: "center", }}>To discuss potential investment opportunities, click the button below.  </b></h5>
                </div>
                <br></br>
                <div className="row row-eq-height justify-content-center">
                  <div className="col-lg-4 mb-4">
                    <center>
                      <Link to="/contact" rel="noopener noreferrer" className="page-button">
                        <b>Connect With Us </b>
                      </Link>
                    </center>
                  </div>
                </div>
              </div>
              <br></br>
            {/* </div>
          </div>
        </div>
      </div> */}
    </section>
  )
}
