
import React from "react"
import "../styles/logo.css"
import "../components/Layout"

import Navigation from "../components/Navigation"
import Includes from "../components/Includes"
import Footer from "../components/Footer"
import Header from "../components/Header"
import Investors from "../components/Investors"


export default function investwithus () {
  return (<>
            <Header/>
            <Navigation/>
            <br></br>
            <Investors/>
            <Includes/>
            <Footer/>

        
  </>)
}